import React from 'react'
import {ButtonContent} from '../global/boton/ButtonContent'

const CallToAction = ({ title, subtitle, bgImages, phone, texts }) => {
  return (
    <div className='flex flex-col md:flex-row items-start md:items-center mt-10'>
      <div className='svgParrallelograBg bgGold w-[60%] h-[80px] md:h-[250px] -mr-20 md:-mr-[350px] relative z-10 flex flex-col justify-center pl-5 md:pl-20'>
        <p className='text-white font-bold'>Call Us Now</p>
        <h2 className='text-white text-[20px] md:text-[50px]'>
          <a href={`tel:+1${phone}`}>
            {phone}
          </a>
        </h2>
      </div>

      <div
        className=' bg-center bg-cover bg-no-repeat w-[100%] relative -top-20 md:top-0 before:absolute before:bg-[#02080e67] before:w-full before:h-full svgParrallelograInverso'
        style={{
          backgroundImage: `url("${bgImages}")`
        }}
      >
        <div className={`relative text-white text-center md:text-start ${texts ? 'py-32 md:py-10' : 'py-24'} px-5 md:pl-[380px]`}>
          <p className='font-bold text-[20px]'>{subtitle}</p>
          <h2 className='pb-10 text-white'>{title}</h2>
          {
            texts ?
              <p className='text-white pb-10'>{texts}</p>
              : null
          }

          <div>
            <ButtonContent btnclases={'botonWhite'} btnStyle='three' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction